import { gql } from "@apollo/client"
import { Box, Field, Grid, Select, TextField, Typography } from "components"
import { functions } from "configs"
import { compose, withFormik, withHooks, withStores } from "enhancers"
import { useUrlParam } from "hooks"
import { isEmpty, times, uniq, uniqBy } from "lodash"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { employeeIsHr } from "utils/helper"

const ResetFilterButton = styled(Box)`
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: ${AppColor["Primary/Primary Text"]};
  :hover {
    cursor: pointer;
  }
`

const FilterClaimHistory = (props: any) => (
  <Box>
    <Box display="flex" justifyContent="space-between" mb={4}>
      <Typography variant="h3">ตัวกรองเพิ่มเติม</Typography>
      <ResetFilterButton onClick={props.clearFilter}>
        <Typography variant="subtitle1">รีเซ็ตตัวกรอง</Typography>
      </ResetFilterButton>
    </Box>
    <Grid container spacing={0}>
      <Box mb={2}>
        <Typography variant="body1">ประเภทสวัสดิการ</Typography>
      </Box>
      <Grid item xs={12} mb={4}>
        <Field
          component={Select}
          name="benefitType"
          type="text"
          placeholder="ประเภทสวัสดิการ"
          options={props.benefitTypeOptions}
          fullWidth
        />
      </Grid>
      <Box mb={2}>
        <Typography variant="body1">ชื่อสวัสดิการ</Typography>
      </Box>
      <Grid item xs={12} mb={4}>
        <Field
          component={Select}
          name="benefitTitle"
          type="text"
          placeholder="ชื่อสวัสดิการ"
          options={props.benefitTitleOptions}
          disabled={!props.values.benefitType}
          fullWidth
        />
      </Grid>
      {props.showHrSection && (
        <>
          <Box mb={2}>
            <Typography variant="body1">ผู้ขอเบิก</Typography>
          </Box>
          <Grid item xs={12} mb={4}>
            <Field
              component={Select}
              name="requester"
              type="text"
              placeholder="ผู้ขอเบิก"
              options={props.requesterOptions}
              fullWidth
            />
          </Grid>
        </>
      )}
      <Box mb={2}>
        <Typography variant="body1">ปีที่เบิก</Typography>
      </Box>
      <Grid item xs={12} mb={4}>
        <Field
          component={Select}
          name="year"
          type="text"
          placeholder="ปีที่เบิก"
          options={props.yearRequestOptions}
          fullWidth
        />
      </Grid>
      <Box mb={2}>
        <Typography variant="body1">เลขอ้างอิง</Typography>
      </Box>
      <Grid item xs={12}>
        <Field component={TextField} name="referenceId" placeholder="เลขอ้างอิง" fullWidth />
      </Grid>
    </Grid>
  </Box>
)

const API = {
  GET_CLAIM_REQUEST_CONFIGS: gql`
    query GET_CLAIM_REQUEST_CONFIGS($employeeId: String!) {
      claimRequestConfigs(employeeId: $employeeId) {
        requests
      }
    }
  `,
}

const enhancer = compose(
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { currentUserInfo, employeeOptions } = props
    const { useState, useQuery, useMemo, useEffect, useCallback, useFormikContext } = hooks

    const formikBag = useFormikContext()
    const { values, setValues, setFieldValue } = formikBag
    const queryParams: any = useUrlParam()
    const { filters } = queryParams

    const currentUser = useMemo(() => {
      return currentUserInfo.employee
    }, [currentUserInfo])

    const [benefitTypeOptions, setBenefitTypeOptions] = useState([])
    const [originalBenefitTitleOptions, setOriginalBenefitTitleOptions] = useState([])
    const [benefitTitleOptions, setBenefitTitleOptions] = useState([])

    useQuery(API.GET_CLAIM_REQUEST_CONFIGS, {
      variables: { employeeId: currentUser.id },
      onCompleted: (data: any) => {
        const current = data.claimRequestConfigs.requests

        const benefitType: any = []
        const benefitTitle: any = []

        current.forEach((item: any) => {
          benefitType.push({
            label: item.type,
            value: item.type,
          })

          benefitTitle.push({
            benefitType: item.type,
            label: item.title,
            value: item.title,
          })
        })

        setBenefitTypeOptions(uniqBy(benefitType, "label") as any)
        setOriginalBenefitTitleOptions(benefitTitle)
      },
      fetchPolicy: "network-only",
    })

    const yearRequestOptions = useMemo(() => {
      const currentYear = new Date().getFullYear()
      const yearsBefore = times(5, (i) => currentYear - i)
      const yearOptions = yearsBefore.map((item) => {
        return { label: item.toString(), value: item }
      })
      return yearOptions
    }, [])

    useEffect(() => {
      if (values.benefitType) {
        const result = originalBenefitTitleOptions.filter((item: any) => item.benefitType === values.benefitType)
        setBenefitTitleOptions(uniqBy(result, "label") as any)
      }

      if (values.benefitType === null) {
        setFieldValue("benefitTitle", null)
      }
    }, [values?.benefitType, originalBenefitTitleOptions, setFieldValue])

    const clearFilter = useCallback(async () => {
      setValues({
        benefitType: undefined,
        benefitTitle: undefined,
        requester: undefined,
        year: undefined,
        referenceId: "",
      })
    }, [setValues])

    const currentUserIsHr = useMemo(() => employeeIsHr(currentUser.role), [currentUser])

    const showHrSection = useMemo(() => {
      // only bam
      if (currentUserIsHr) return currentUserIsHr !== "approvers10kLv6"
      return false
    }, [currentUserIsHr])

    useEffect(() => {
      setValues({
        ...filters,
        year: filters?.year ? Number(filters?.year) : undefined,
      })
    }, [filters, setValues])

    console.log(values)

    return {
      benefitTypeOptions,
      benefitTitleOptions,
      values,
      clearFilter,
      setFieldValue,
      yearRequestOptions,

      showHrSection,
      requesterOptions: employeeOptions,
    }
  }),
)

export default enhancer(FilterClaimHistory)
